import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ServicesCatalogCardComponent } from '@domains/services-catalog/services-catalog-card/services-catalog-card.component';
import { ServicesCatalogContentService } from '@domains/services-catalog/services-catalog-content/services-catalog-content.service';

@Component({
  selector: 'app-services-catalog-content',
  standalone: true,
  imports: [ServicesCatalogCardComponent],
  providers: [ServicesCatalogContentService],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->

    <div class="services-catalog-content-list">
      @for (service of service.services(); track service.name) {
        <app-services-catalog-card [service]="service" />
      }
    </div>
  `,
  styles: [
    `
      @use '@wizbii-components/styles/src/breakpoints' as breakpoints;

      .services-catalog-content-list {
        display: grid;
        grid-template-columns: var(--list-template-columns, auto);
        gap: 1.25rem;

        @include breakpoints.minWidth('xl') {
          --list-template-columns: repeat(2, 1fr);

          grid-auto-rows: minmax(19.5625rem, 1fr);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesCatalogContentComponent {
  readonly service = inject(ServicesCatalogContentService);
}
