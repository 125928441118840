import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  computed,
  effect,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { BannerComponent } from '@commons/banner';
import { TabsNavBarComponent } from '@commons/nav-bar/tabs-nav-bar.component';
import { ServicesCatalogContentComponent } from '@domains/services-catalog/services-catalog-content/services-catalog-content.component';
import { ServicesCatalogService } from '@features/services-catalog/services-catalog.service';
import { FilteringNavBarConfig } from '@models/filtering-nav-bar/filtering-nav-bar.interface';
import { ServicesPage } from '@models/services/services-page';
import { Store } from '@ngrx/store';
import { productName } from '@stores/partner/partner.selectors';
import { selectThemes } from '@stores/themes/themes.selectors';
import { ScrollToConfigOptions, ScrollToModule, ScrollToService } from '@wizbii/ngx-scroll-to';

const SERVICES_LIST_ID = 'services-list-id';

const SERVICES_CATALOG_SCROLL_CONFIG: ScrollToConfigOptions = {
  target: SERVICES_LIST_ID,
  offset: -200,
  duration: 500,
  easing: 'easeOutElastic',
};

export const ALL_CATEGORIES = 'all';

@Component({
  selector: 'app-services-catalog',
  standalone: true,
  imports: [BannerComponent, ServicesCatalogContentComponent, TabsNavBarComponent, ScrollToModule],
  providers: [ServicesCatalogService],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    @if (viewModel(); as vm) {
      <app-banner
        class="tw-mb-4 tw-mt-5"
        [title]="vm.banner.title"
        [text]="vm.banner.text"
        [imageUrl]="vm.banner.imageUrl"
      />

      @if (vm.navConfig; as navConfig) {
        <app-tabs-navbar [navConfig]="navConfig" (clickedItem)="servicesCatalogService.emitTracker($event)" />
      }

      <section class="services-catalog" [id]="vm.SERVICES_LIST_ID" #servicesList>
        <app-services-catalog-content />
      </section>
    }
  `,
  styles: [
    `
      @use '@wizbii-components/styles/src/breakpoints' as breakpoints;

      .services-catalog {
        --scroll-offset: -80;
        display: grid;
        margin: 1rem 0;

        gap: var(--gap, 1.5rem);
        background-color: var(--background-color);
        padding: var(--padding);
        border-radius: var(--border-radius);
        border: var(--border);

        @include breakpoints.notHandset {
          --scroll-offset: -200;
          --gap: 1.875rem;
          --background-color: white;
          --padding: 1rem 1.5rem;
          --border-radius: var(--loyal-border-radius);
          --border: 1px solid rgb(var(--loyal-border-light));
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesCatalogComponent {
  readonly servicesCatalogService = inject(ServicesCatalogService);
  readonly #store = inject(Store);
  readonly #scrollToService = inject(ScrollToService);

  readonly category = input.required<string | undefined>();
  readonly navConfig = input.required<FilteringNavBarConfig | null>();
  readonly servicesPageConfig = input.required<ServicesPage>();

  private readonly servicesListElement = viewChild.required<ElementRef<HTMLElement>>('servicesList');

  readonly #platformName = this.#store.selectSignal(productName);
  readonly #themes = this.#store.selectSignal(selectThemes);
  readonly #currentTheme = computed(() => {
    const category = this.category();
    const themes = this.#themes();

    if ([undefined, ALL_CATEGORIES].includes(category)) {
      return null;
    }

    const currentTheme = themes.find((theme) => theme.id === category);

    if (currentTheme === undefined) {
      return null;
    }

    return currentTheme;
  });

  constructor() {
    effect(() => {
      const category = this.category();

      if (category === undefined) return;

      this.#scrollToService.scrollTo({ ...SERVICES_CATALOG_SCROLL_CONFIG, offset: this.#calculateOffset() });
    });
  }

  protected viewModel = computed(() => {
    const platformName = this.#platformName();
    const currentTheme = this.#currentTheme();

    if (!platformName) return null;

    const heroConfig = this.servicesPageConfig().heroDisplayConfiguration;
    const banner = {
      title: heroConfig.labels.title,
      text: heroConfig.labels.description,
      imageUrl: heroConfig.backgroundImage,
    };

    return {
      platformName,
      currentTheme,
      navConfig: this.navConfig(),
      banner,
      SERVICES_LIST_ID,
    };
  });

  readonly #calculateOffset = (): number =>
    Number(getComputedStyle(this.servicesListElement().nativeElement).getPropertyValue('--scroll-offset'));
}
