import { Injectable } from '@angular/core';
import { FilteringNavBarItem } from '@models/filtering-nav-bar/filtering-nav-bar.interface';
import { trackEvent } from '@wizbii/utils/tracking';

@Injectable()
export class ServicesCatalogService {
  readonly trackEvent = trackEvent;

  readonly emitTracker = (item: FilteringNavBarItem): void => {
    if (item.tracker) {
      this.trackEvent(item.tracker.category, item.tracker.action, item.tracker.label, item.tracker.value);
    }
  };
}
