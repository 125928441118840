import { Injectable } from '@angular/core';
import { trackEvent } from '@wizbii/utils/tracking';

@Injectable()
export class ServicesCatalogCardService {
  readonly trackEvent = trackEvent;

  readonly onCtaClick = (serviceId: string) => {
    this.trackEvent('page de liste des services', 'click', `[service-list]en savoir plus - ${serviceId}`);
  };
}
