import { Injectable, computed, inject } from '@angular/core';
import { ALL_CATEGORIES } from '@features/services-catalog/services-catalog.component';
import { getIsLockedAndMinimumRequiredPlanLabel } from '@global/utils/utils';
import { Theme } from '@loyalty-v3/libs';
import { Service } from '@models/services/service';
import { Store } from '@ngrx/store';
import { userPlans } from '@stores/plan/plan.selectors';
import { selectProfile } from '@stores/profile/profile.selectors';
import { selectQueryParam } from '@stores/router/router.selectors';
import { userServices } from '@stores/services/services.selector';
import { selectThemes } from '@stores/themes/themes.selectors';

@Injectable()
export class ServicesCatalogContentService {
  readonly #store = inject(Store);

  readonly #userServices = this.#store.selectSignal(userServices);
  readonly #categoryQueryParam = this.#store.selectSignal(selectQueryParam('category'));
  readonly #themes = this.#store.selectSignal(selectThemes);
  readonly #profile = this.#store.selectSignal(selectProfile);
  readonly #userPlans = this.#store.selectSignal(userPlans);

  readonly #filteredServices = computed(() => {
    const services = this.#userServices();
    const category = this.#categoryQueryParam();
    const themes = this.#themes();

    if (!services || !themes) {
      return [];
    }

    if (category === undefined || category === ALL_CATEGORIES) {
      return services;
    }

    return this.#getServicesFromThemeId(services, category, themes);
  });

  readonly services = computed(() => {
    const services = this.#filteredServices();
    const profile = this.#profile();
    const userPlans = this.#userPlans();

    if (!services || !profile || !userPlans) {
      return [];
    }

    return this.#sortServiceByIndex([...services]).map((service) => ({
      id: service.id,
      name: service.name,
      logo: service.assets.logo,
      title: service.labels.catchPhrase,
      description: service.labels.catchPhraseDetails,
      imageSrc: service.assets.mainImage!,
      highlightCta: service.labels.highlightCta,
      route: ['/', 'services', service.id],
      index: service.index,
      ...getIsLockedAndMinimumRequiredPlanLabel(service, profile, userPlans),
    }));
  });

  readonly #sortServiceByIndex = (services: Service[]): Service[] => {
    return services.toSorted(({ index: index_a }, { index: index_b }) => {
      if (index_a === null || index_b === null) {
        return 0;
      }
      if (index_a > index_b) {
        return 1;
      }

      if (index_a < index_b) {
        return -1;
      }

      return 0;
    });
  };

  readonly #getServicesFromThemeId = (services: Service[], themeId: string | string[], themes: Theme[]): Service[] => {
    const theme = Array.isArray(themeId)
      ? themes.find((theme) => themeId.includes(theme.id))
      : themes.find((theme) => theme.id === themeId);
    return services.filter((service) => theme?.serviceIds.includes(service.id));
  };
}
