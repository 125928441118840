import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PremiumTagComponent } from '@commons/premium-tag/premium-tag.component';
import { ServicePresentationLogoComponent } from '@domains/service-presentation-logo/service-presentation-logo.component';
import { ServiceCatalog } from '@domains/services-catalog/models';
import { ServicesCatalogCardService } from '@domains/services-catalog/services-catalog-card/services-catalog-card.service';
import { ImageLoaderDirective } from '@global/utils/image-loader';

@Component({
  selector: 'app-services-catalog-card',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    ServicePresentationLogoComponent,
    PremiumTagComponent,
    ImageLoaderDirective,
  ],
  providers: [ServicesCatalogCardService],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->

    @if (service(); as service) {
      <a
        class="container"
        [routerLink]="service.route"
        [attr.aria-labelledby]="service.id"
        (click)="internalService.onCtaClick(service.id)"
        tabindex="0"
      >
        <p class="cdk-visually-hidden" [id]="service.id">aller à la page du service {{ service.name }}</p>
        <header class="container__header">
          <app-service-presentation-logo
            class="container__header__logo"
            [logoSrc]="service.logo"
            [serviceName]="service.name"
            [size]="5"
          />
          <div class="container__header__right">
            @if (service.isLocked) {
              <app-premium-tag class="container__header__right__premium-tag" />
            }
            <p class="container__header__right__title">{{ service.name }}</p>
          </div>
        </header>
        <div class="container__image-container">
          <img class="container__image" [src]="service.imageSrc" width="643" height="433" alt="" />
        </div>
        <div class="container__content">
          <h2 class="container__title">{{ service.title }}</h2>
          <p class="container__description">{{ service.description }}</p>
        </div>
        <footer class="container__footer">
          <span [attr.aria-labelledby]="service.id" obendy-button>{{ service.highlightCta }}</span>
        </footer>
      </a>
    }
  `,
  styles: [
    `
      @use '@wizbii-components/styles/src/breakpoints' as breakpoints;
      @use './mixins/multi-line-ellipsis' as ellipsis;
      @use '@angular/cdk';

      .container {
        @include cdk.a11y-visually-hidden;

        /* stylelint-disable-next-line no-duplicate-selectors */
        & {
          --background-linear-gradient: linear-gradient(
            90deg,
            rgb(var(--loyal-header-black) / 100%),
            rgb(var(--loyal-header-black) / 100%),
            rgb(var(--loyal-header-black) / 60%),
            rgb(var(--loyal-header-black) / 50%)
          );
          --dark-color: rgb(var(--loyal-header-black));
          --light-color: white;
          --container-padding: 1.5rem;

          position: relative;
          border-radius: var(--loyal-border-radius);
          background-color: var(--dark-color);
          padding: var(--container-padding);
          overflow: hidden;
          height: 100%;
          display: grid;
          gap: 1rem;
          grid-template-columns: minmax(0, 1fr);
          will-change: transform;
          transition: transform 300ms ease-in-out;
          text-decoration: none;
        }

        &:hover,
        &:focus {
          transform: scale(1.02);
        }

        &__header {
          position: relative;
          display: flex;
          gap: 1rem;
          z-index: 1;
          align-self: start;

          &__right {
            display: flex;
            flex-direction: column;
            gap: 0.375rem;
            align-self: center;

            &__title {
              color: var(--light-color);
              font-size: 1rem;
              line-height: 150%;
            }
          }
        }

        &__image-container {
          display: flex;
          justify-content: flex-end;
          position: absolute;
          overflow: hidden;
          width: 100%;
          height: 100%;
          top: 0;

          &::before {
            content: '';
            background: var(--background-linear-gradient);
            position: absolute;
            inset: 0;
          }
        }

        &__image {
          object-fit: cover;
          object-position: top right;
          height: 100%;
          width: 100%;
        }

        &__content {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          gap: 0.5rem;
          line-height: 1.4 !important;
        }

        &__title,
        &__description {
          --h2-color: var(--light-color);
          --obendy-text-color: var(--light-color);

          position: relative;
        }

        &__footer {
          position: relative;
          align-self: end;
          display: grid;
          justify-items: start;
          width: fit-content;
        }

        @container (min-width: 35rem) {
          height: 19.6875rem;
          display: flex;
          flex-direction: column;

          &__content {
            height: 100%;
          }

          &__image {
            object-fit: contain;
          }
        }

        @container (min-width: 42.5rem) {
          &__image {
            object-fit: cover;
            width: 100%;
          }
        }

        @include breakpoints.notHandset {
          --container-padding: 1.375rem;

          &__title {
            @include ellipsis.clamp(2);

            max-width: 28.125rem;
          }

          &__description {
            @include ellipsis.clamp(3);

            max-width: 32.5rem;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesCatalogCardComponent {
  readonly internalService = inject(ServicesCatalogCardService);

  readonly service = input.required<ServiceCatalog>();
}
